<template>
  <div class="approvalOfPurchaseOrders">
    <q-pull-to-refresh @refresh="refresh" class="pullRefresh">
      <DataCard
        :data="allFilters"
        :fields="fields"
        :actions="actions"
        @approve="approveOrder"
        @reject="rejectOrder"
        @cardClick="detailOrder"
        @filterR="filterBySupplierName"
        @filterB="filterByBranchCode"
        filterData
        v-if="cards"
      />
    </q-pull-to-refresh>
    <div class="RejectionReason">
      <q-dialog v-model="modalReason" persistent square full-width>
        <q-card class="bg-white">
          <q-card-section>
            <div class="text-h6">Rejeitar Pedido de Compra</div>
            <div class="text-subtitle2">
              Favor informar o motivo da rejeição:
            </div>
          </q-card-section>
          <q-card-section>
            <q-input
              v-model="descriptionRejection"
              type="textarea"
              label="Descrição do Motivo"
              outlined
            />
          </q-card-section>
          <q-card-section>
            <div class="row justify-evenly q-mb-xl">
              <div class="col-auto">
                <q-btn
                  color="grey-8"
                  label="Cancelar"
                  @click="modalReason = false"
                />
              </div>
              <div class="col-auto">
                <q-btn color="red" label="Rejeitar" @click="confirmReject" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DataCard from "../../components/DataCard";
import removeCharSpecial from "../../helpers/removerCharSpecial";

export default {
  components: {
    DataCard
  },
  data() {
    return {
      fields: [
        {
          field: "supplier",
          label: "",
          type: "string",
          class: "text-bold"
        },
        { field: "", label: "", type: "separator" },
        {
          field: "branch",
          label: "",
          type: "string",
          class: "text-body2"
        },
        {
          field: "amount",
          label: "",
          type: "currency",
          class: "text-overline"
        }
      ],
      actions: [
        {
          label: "Aprovar",
          color: "green",
          icon: "check",
          eventEmit: "approve"
        },
        { label: "Rejeitar", color: "red", icon: "block", eventEmit: "reject" }
      ],
      filterWebR: null,
      filterWebB: null,
      filterR: sessionStorage.filterWebR,
      filterB: sessionStorage.filterWebB,
      cards: true,
      modalReason: false,
      descriptionRejection: null,
      orderSelected: null
    };
  },
  computed: {
    ...mapState("purchaseOrders", ["orders"]),

    allFilters() {
      //~> Declarando variável que armazenará o Retorno das requisições
      let orders = this.orders;

      switch (sessionStorage.sortWeb) {
        case "sSmLg":
          orders.sort((a, b) => a.amountOfOrder - b.amountOfOrder);
          break;
        case "sLgSm":
          orders.sort((a, b) => b.amountOfOrder - a.amountOfOrder);
          break;
        case "sNewOld":
          orders.sort((a, b) => b.issueDate - a.issueDate);
          break;
        case "sOldNew":
          orders.sort((a, b) => a.issueDate - b.issueDate);
          break;
        default:
          break;
      }

      if (this.filterR) {
        orders = orders.filter(order => {
          return (
            order.orderNumber
              .toUpperCase()
              .trim()
              .indexOf(this.filterR.toUpperCase().trim()) >= 0 ||
            order.supplierName
              .toUpperCase()
              .trim()
              .indexOf(this.filterR.toUpperCase().trim()) >= 0
          );
        });
      }

      if (this.filterB) {
        if (sessionStorage.filterWebB) {
          orders = orders.filter(ord => {
            return (
              ord.branchCode.trim().indexOf(sessionStorage.filterWebB.trim()) >=
              0
            );
          });
        } else {
          orders = orders.filter(ord => {
            return ord.branchCode.trim().indexOf(this.filterB.trim()) >= 0;
          });
        }
      }

      return orders.map(order => {
        return {
          id: order.id,
          supplier: `${
            order.orderNumber
          } - ${order.supplierName.toUpperCase()}`,
          branch: `${order.branchCode} - ${order.branchName.toUpperCase()}`,
          amount: order.amountOfOrder,
          issueDate: order.issueDate,
          currency: order.currency,
          orderNumber: order.orderNumber
        };
      });
    }
  },
  methods: {
    ...mapActions("purchaseOrders", [
      "getOrders",
      "approvesRejectsPurchaseOrder"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    approveOrder(order) {
      this.sendResponse(order, "approved", "APROVAR", "text-green");
    },
    rejectOrder(order) {
      this.orderSelected = order;
      this.modalReason = true;
    },
    sendResponse(order, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> o Pedido ${order.orderNumber}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            const reasonReject =
              response === "rejected"
                ? removeCharSpecial(this.descriptionRejection)
                : "approved";
            await this.approvesRejectsPurchaseOrder({
              order,
              response,
              reasonReject
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.getOrders();
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    },
    confirmReject() {
      if (String(this.descriptionRejection).trim().length < 8) {
        this.$q.dialog({
          title: "Refresa Aprova",
          message: "Favor detalhar o motivo da Rejeição deste pedido!"
        });

        return;
      }

      this.sendResponse(this.orderSelected, "rejected", "REJEITAR", "text-red");
      this.modalReason = false;
    },
    detailOrder(orderId) {
      this.$router.push({
        name: "orderDetail",
        params: { orderId }
      });
    },
    filterBySupplierName(filterR) {
      this.filterR = filterR;
    },
    filterByBranchCode(filterB) {
      if (sessionStorage.filterWebB && !filterB) {
        this.filterB = sessionStorage.filterWebB;
      } else {
        this.filterB = filterB;
        sessionStorage.setItem("filterWebB", filterB);
      }
    },
    async refresh(done) {
      await this.getOrders();
      done();
    }
  },
  created() {
    this.getOrders();
  }
};
</script>
<style lang="scss">
.approvalOfPurchaseOrders {
  width: 100%;
}
</style>
